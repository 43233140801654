import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M11.6654 11.2513H13.7487L14.582 7.91797H11.6654V6.2513C11.6654 5.39349 11.6654 4.58464 13.332 4.58464H14.582V1.78472C14.3106 1.74868 13.2845 1.66797 12.2011 1.66797C9.93903 1.66797 8.33203 3.04869 8.33203 5.58439V7.91797H5.83203V11.2513H8.33203V18.3346H11.6654V11.2513Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }