import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M18.307 5.43125C18.2334 5.14324 18.0924 4.87686 17.8955 4.65409C17.6987 4.43132 17.4517 4.25854 17.175 4.15C14.4969 3.11563 10.2344 3.125 10 3.125C9.76562 3.125 5.50312 3.11563 2.825 4.15C2.54825 4.25854 2.30128 4.43132 2.10445 4.65409C1.90762 4.87686 1.76658 5.14324 1.69297 5.43125C1.49063 6.21094 1.25 7.63594 1.25 10C1.25 12.3641 1.49063 13.7891 1.69297 14.5688C1.76647 14.8569 1.90746 15.1234 2.1043 15.3464C2.30113 15.5693 2.54816 15.7422 2.825 15.8508C5.39062 16.8406 9.40625 16.875 9.94844 16.875H10.0516C10.5938 16.875 14.6117 16.8406 17.175 15.8508C17.4518 15.7422 17.6989 15.5693 17.8957 15.3464C18.0925 15.1234 18.2335 14.8569 18.307 14.5688C18.5094 13.7875 18.75 12.3641 18.75 10C18.75 7.63594 18.5094 6.21094 18.307 5.43125ZM12.5461 10.5094L9.42109 12.6969C9.32761 12.7624 9.21799 12.801 9.1041 12.8086C8.99022 12.8162 8.87643 12.7925 8.77508 12.74C8.67373 12.6875 8.58868 12.6083 8.52917 12.5109C8.46966 12.4135 8.43796 12.3016 8.4375 12.1875V7.8125C8.43753 7.69817 8.46893 7.58603 8.52827 7.4883C8.5876 7.39057 8.67261 7.31099 8.77404 7.25823C8.87547 7.20546 8.98943 7.18152 9.10352 7.18903C9.21761 7.19653 9.32745 7.23518 9.42109 7.30078L12.5461 9.48828C12.6283 9.54593 12.6954 9.62253 12.7417 9.7116C12.7881 9.80068 12.8123 9.8996 12.8123 10C12.8122 10.1004 12.7881 10.1993 12.7417 10.2884C12.6954 10.3775 12.6283 10.4541 12.5461 10.5117V10.5094Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }